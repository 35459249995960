<template>
  <v-container class="get-more-points">
    <h4 class="text-uppercase">{{ $t("getMorePoints.title") }}</h4>
    <v-form @submit.prevent="saveData">
      <div class="d-flex align-center">
        <div>
          <img src="/img_layout/points/stella-20.png" alt="stella-20" />
        </div>
        <div class="flex-grow-1 pl-2">
          <v-text-field
            color="primary"
            v-model="userData.phone"
            :label="$t('getMorePoints.phone')"
            dense
            outlined
            rounded
            hide-details="auto"
          ></v-text-field>
        </div>
      </div>
      <v-divider class="my-4"></v-divider>
      <div>
        <div class="text-body-0 font-weight-semibold">
          {{ $t("getMorePoints.online") }}
        </div>
        <v-radio-group v-model="userData.onlineShopping">
          <v-radio
            v-for="(n, i) in onlineShoppingOptions"
            :key="i"
            :label="n.label"
            :value="n.value"
          ></v-radio>
        </v-radio-group>
      </div>
      <v-divider class="my-4"></v-divider>
      <div class="d-flex align-center">
        <div>
          <img src="/img_layout/points/stella-20.png" alt="stella-20" />
        </div>
        <div class="flex-grow-1 px-2 font-weight-semibold">
          {{ $t("getMorePoints.familyMembers") }}<br />
          <span class="font-weight-regular"
            >({{ $t("getMorePoints.excludingYou") }})</span
          >
        </div>
        <div>
          <v-select
            :items="numbers"
            dense
            outlined
            rounded
            hide-details="auto"
            class="custom-select"
            width="100px"
            v-model="userData.familyMembers"
          ></v-select>
        </div>
      </div>
      <div class="mt-6">
        <div class="text-body-0 font-weight-semibold">
          {{ $t("getMorePoints.online") }}
        </div>
        <v-radio-group v-model="userData.pets">
          <v-radio :label="$t('common.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.no')" :value="false"></v-radio>
        </v-radio-group>
      </div>
      <div>
        <v-btn
          :loading="loading"
          type="submit"
          color="primary"
          large
          block
          depressed
          rounded
        >
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>
<style lang="scss">
.get-more-points {
  .custom-select {
    max-width: 100px;
    .v-select__selection--comma {
      font-size: 14px;
      color: var(--v-default-base) !important;
      font-weight: 500;
    }
  }
}
</style>
<script>
export default {
  name: "GetMorePoints",
  data() {
    return {
      userData: { familyMembers: 0 },
      loading: false,
      onlineShoppingOptions: [
        { label: "Mai", value: "1" },
        { label: "Raramente", value: "2" },
        { label: "Spesso", value: "3" },
        { label: "Regolarmente", value: "4" }
      ],
      numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  methods: {
    saveData() {
      console.log(this.userData);
    }
  }
};
</script>
